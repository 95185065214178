import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Avatar from '../components/atoms/Avatar';
import Certificates from '../components/molecules/Certificates';
import Enterance from '../components/atoms/Enterance';
import Map from '../components/atoms/Map';

const Wrapper = styled.section`
  margin: 1rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    margin: 3rem 28rem 5rem;
  }
  ::after {
    content: '';
    display: block;
    clear: both;
  }
`;

const MapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
`;

const StyledParagraph = styled.p`
  font-family: ${({ theme }) => theme.font.family.main};
  font-weight: 300;
  color: ${({ theme }) => theme.font.color.main};
  margin-top: 2.5rem;
  font-size: 1.5rem;
`;

const StyledHeader = styled.h1`
  font-family: ${({ theme }) => theme.font.family.main};
  font-weight: 600;
  color: ${({ theme }) => theme.font.color.main};
  font-size: 2.5rem;
`;

const query = graphql`
  {
    datoCmsTrimsalon {
      text2
      text
      header
    }
  }
`;

const Trimsalon = () => {
  const data = useStaticQuery(query);
  const { header, text, text2 } = data.datoCmsTrimsalon;
  return (
    <>
      <Wrapper>
        <Avatar />
        <StyledHeader>{header}</StyledHeader>
        <StyledParagraph>{text}</StyledParagraph>
      </Wrapper>
      <Wrapper>
        <StyledParagraph>{text2}</StyledParagraph>
        <Certificates />
        <MapWrapper>
          <Enterance />
          <Map />
        </MapWrapper>
      </Wrapper>
    </>
  );
};

export default Trimsalon;
