import React from 'react';
import styled from 'styled-components';

const StyledIframe = styled.iframe`
  width: 60rem;
  height: 40rem;
  max-width: 100%;
  border: 0;
`;

const Map = () => (
  <StyledIframe
    src={
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2506.1794025911267!2d5.967331015913461!3d51.0866965491962!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c0b6c639b43377%3A0xa9d8268cd3c6e103!2sHondentrimsalon%20Happy%20Puppy!5e0!3m2!1spl!2spl!4v1588356188032!5m2!1spl!2spl'
    }
  ></StyledIframe>
);

export default Map;
