import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    flex-direction: row;
  }
`;

const StyledImg = styled.img`
  height: auto;
  width: calc(100% - 5rem);
  margin: 2.5rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    width: 30%;
    margin: 8rem 1.5rem;
    justify-content: space-evenly;
  }
`;

const query = graphql`
  {
    datoCmsTrimsalon {
      certificates {
        url
        originalId
      }
    }
  }
`;

const Certificates = () => {
  const data = useStaticQuery(query);
  const certificates = data.datoCmsTrimsalon.certificates;
  return (
    <Wrapper>
      {certificates.map(certificate => (
        <StyledImg src={certificate.url} key={certificate.originalId} />
      ))}
    </Wrapper>
  );
};

export default Certificates;
