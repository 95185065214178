import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

const AvatarWrapper = styled.div`
  width: 14.9rem;
  height: 15.2rem;
  margin: 2.5rem auto;
  overflow: hidden;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    height: 22.9rem;
    width: 22.9rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    float: left;
    margin: 2.1rem;
  }
`;

const StyledAvatarImage = styled.img`
  width: 100%;
  height: auto;
`;

const query = graphql`
  {
    datoCmsTrimsalon {
      photo {
        url
      }
    }
  }
`;

const Avatar = () => {
  const data = useStaticQuery(query);
  const { url } = data.datoCmsTrimsalon.photo;
  return (
    <AvatarWrapper>
      <StyledAvatarImage src={url} />
    </AvatarWrapper>
  );
};

export default Avatar;
