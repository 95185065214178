import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

const EnteranceImage = styled.img`
  max-height: 100%;
  max-width: 100%;
  object-position: center;
`;

const EnteranceWrapper = styled.div`
  overflow: hidden;
  width: 30rem;
  margin: 0 auto;
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    margin-right: 2rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    margin-right: auto;
  }
`;

const query = graphql`
  {
    datoCmsTrimsalon {
      enterance {
        url
      }
    }
  }
`;

const Enterance = () => {
  const data = useStaticQuery(query);
  const { url } = data.datoCmsTrimsalon.enterance;
  return (
    <EnteranceWrapper>
      <EnteranceImage src={url} />
    </EnteranceWrapper>
  );
};

export default Enterance;
